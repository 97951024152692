import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Pricing from '../../Components/Pricing/Pricing'
import './PricingPage.scss'

function PricingPage() {
    return (
        <div id='pricePage'>
            <Navbar
                homeNav='navbar navbar-expand-md navbar-dark bg-custom fixed-top'
            />
            <div className='spacer' />
            <Pricing />
        </div>
    )
}

export default PricingPage