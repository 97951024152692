import React, { useEffect, useState } from 'react'
import './HomeDash.scss';
import slideshow from '../../Images/slideshow.jpeg'
import discord from '../../Images/discordLogo.svg'
import twitter from '../../Images/twitterLogo.svg'
import UpcomingReleases from '../../Components/UpcomingReleases/UpcomingReleases'
import axios from 'axios';

import Loader from '../Loader/Loader'

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const HomeDashboard = () => {

    // const load = true
    const [release, setRelease] = useState([]);
    const [slide, setSlide] = useState([]);
    const [error, setError] = useState(null);
    const backendUrl = `${process.env.REACT_APP_BACKEND_URL_TEST}`;
    const username = localStorage.getItem("username");

    // Pull Releases
    useEffect(() => {
        async function fetchRelease() {
            try {
                await axios.get(`http://localhost:1337/api/releases?populate=*`)
                    // await axios.get(`http://admin.gravityproxies.com/api/releases`)
                    .then(response => setRelease(response.data.data));

            } catch (error) {
                setError(error)
            }
        }
        fetchRelease();
    }, [])

    // Pull Slideshow
    useEffect(() => {
        async function fetchSlide() {
            try {
                await axios.get(`http://localhost:1337/api/slideshows?populate=*`)
                    .then(response => setSlide(response.data.data));

            } catch (error) {
                setError(error)
            }
        }
        fetchSlide();
    }, [])

    // console.log(release)

    if (slide.length) {
        return (
            <div id="HomeDash">
                <div className="TopRow">
                    <div className="imageDisplay">
                        <Carousel
                            showStatus={false}
                            swipeable={true}
                            emulateTouch={true}
                            infiniteLoop={true}
                            autoPlay={true}
                            showThumbs={false}
                            transitionTime={500}
                            interval={3000}
                            swipeScrollTolerance={5}
                            showArrows={false}
                        >
                            {slide.map((item, index) => (
                                <a key={index} href={item.attributes.slideLink}>
                                    <img className="slideshowImg" src={`${backendUrl + item.attributes.slideshowImage.data[0].attributes.formats.large.url}`} alt="slideshow" />
                                </a>
                            ))}
                        </Carousel>
                    </div>
                    <div className="stayConnected">
                        <div className='connectedContent'>
                            <h1>Welcome Back, {username}</h1>
                            <p>Glad to have you back, check out some of the lastest releases below!</p>
                        </div>

                        <div className='connectedContent'>
                            <h1>Stay connected</h1>
                            <p>Want to stay up to date with the latest Gravity Proxies news and discounts? Sign up & follow our socials.</p>
                            <div className="socialBtns">
                                <a href="https://discord.gg/XQ6QHtE5Nu" target="_blank" className='discordBtn'>
                                    <img src={discord} alt="pfp" />
                                </a>
                                <a href="https://twitter.com/gravity_proxies" target="_blank" className='twitterBtn'>
                                    <img src={twitter} alt="pfp" />
                                </a>
                            </div>
                        </div>

                        {/* <h1 className='mailListText'>Join our Mailing List</h1>
                            <div className='mailListContent'>
                                <textarea placeholder="Enter email" className='emailTextbox'></textarea>
                                <a href='/' className='joinBtn'>Join</a>
                            </div> */}
                    </div>
                </div>
                <div className="upcomingReleases">
                    <div className="releasesContent">
                        <h1>Upcoming Releases</h1>
                        <div className="releaseComponents">
                            {release.map((item, index) => (
                                <UpcomingReleases
                                    key={index}
                                    siteLink={item.attributes.siteUrl}
                                    sneakerName={item.attributes.sneakerName}
                                    sneakerDate={item.attributes.sneakerDate}
                                    iteName={item.attributes.siteName}
                                    sneakerImage={item.attributes.sneakerImage.data.attributes.formats.thumbnail.url} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        );
    }
    return <Loader />
}

export default HomeDashboard