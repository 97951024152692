import React, { useState, useEffect } from 'react'
import axios from "axios";
import "./Pricing.scss";
// const baseUrl = "http://localhost:1337";
function Pricing() {
  // const [data, setData] = useState(null);
  // const [Seconddata, setSecondData] = useState(null);

  // const [link, setLink] = useState("https://buy.stripe.com/6oE8yMeRI2eS5LW9Bj");
  /* useEffect(() => {
    async function fetchProduct() {
      const response = await axios.get(
        " http://localhost:1337/strapi-stripe/getProduct/1"
      );
      setData(response.data);
    }
    async function fetchProducts() {
      const response = await axios.get(
        " http://localhost:1337/strapi-stripe/getProduct/2"
      );
      setSecondData(response.data);
    }
    fetchProduct();
    fetchProducts();
  }, []);
  const newArray = [{
    data: data
  }, {
    data: Seconddata
  }]
  console.log(newArray) */


  const [product, setProduct] = useState([]);
  const [setError] = useState(null);

  // Pull Products
  useEffect(() => {
    async function fetchProduct() {
      try {
        await axios.get(`http://admin.gravityproxies.com/api/products?populate=*`)
          // await axios.get(`http://admin.gravityproxies.com/api/releases`)
          .then(response => setProduct(response.data.data));

      } catch (error) {
        setError(error)
      }
    }
    fetchProduct();
  }, [])

  function Product({ productTitle, productSubTitle, inStock, feature1, feature2, feature3, feature4, feature5, feature6, planLink1, planPrice1, planAmount1, planLink2, planPrice2, planAmount2 }) {
    const [amount, setAmount] = useState(planPrice1);
    return (
      <div className="pricingCard">
        <div className="priceHeader">
          <h4>{productSubTitle}</h4>
          <h3>{productTitle}</h3>
        </div>
        <div className="priceBody">
          <li>{feature1}</li>
          <li>{feature2}</li>
          <li>{feature3}</li>
          <li>{feature4}</li>
          <li>{feature5}</li>
          <li>{feature6}</li>
        </div>
        <div className="select-wrapper">
          <select
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          >
            <option value="" disabled>
              Select Amount
            </option>
            <option value={planPrice1} defaultValue>{planAmount1}</option>
            {/* <option value="100">200 Proxies</option>
            <option value="150">300 Proxies</option>
            <option value="250">500 Proxies</option>
            <option value="450">1000 Proxies</option>
            <option value="800">2000 Proxies</option>
            <option value="1050">3000 Proxies</option> */}
          </select>
        </div>
        <div className="text-center">
          <a
            href={
              amount === planPrice1
                ? planLink1

                : amount === planPrice2
                  ? planLink2

                  : ""
            }
          >
            <div className={inStock == true ? 'btn purchaseButton text-nowrap' : 'btn purchaseButton text-nowrap soldOut disabled'}>
              {inStock == true ? 'Purchase - $' + amount : 'Sold Out'}
            </div>
          </a>
        </div>
      </div>
    )
  }


  return (
    <div id="pricing2">
      <div id="pricing">
        <div className="pricingContainer">
          <div className="sectionHeader">
            <h2>
              <span style={{ color: "#756E8D" }}>Your success starts here</span>
            </h2>
            <h1>Pricing</h1>
          </div>

          <div className="priceScroll" id="pricing">
            {product.map((item, index) => (
              <Product
                key={index}
                productTitle={item.attributes.productTitle}
                productSubTitle={item.attributes.productSubTitle}
                inStock={item.attributes.inStock}
                feature1={item.attributes.feature1}
                feature2={item.attributes.feature2}
                feature3={item.attributes.feature3}
                feature4={item.attributes.feature4}
                feature5={item.attributes.feature5}
                feature6={item.attributes.feature6}

                planLink1={item.attributes.plans.data[0].attributes.planLink}
                planPrice1={item.attributes.plans.data[0].attributes.planPrice}
                planAmount1={item.attributes.plans.data[0].attributes.planAmount}

              // Need to parse all plans
              // planLink2={item.attributes.plans.data[1].attributes.planLink}
              // planPrice2={item.attributes.plans.data[1].attributes.planPrice}
              // planAmount2={item.attributes.plans.data[1].attributes.planAmount}
              />
            ))}
          </div>

          <div className="text-center">
            <p className="termsText">
              By purchasing you agree to our{" "}
              <a href="/terms">terms of service</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing