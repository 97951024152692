import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Loader from '../Components/Loader/Loader'

const backendUrl = `${process.env.REACT_APP_BACKEND_URL_TEST}`;

const LoginRedirect = (props) => {
    const [text, setText] = useState('Loading...');
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        // Successfully logged with the provider
        // Now logging with strapi by using the access_token (given by the provider) in props.location.search
        fetch(`${backendUrl}/api/auth/discord/callback${location.search}`)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
                }
                return res;
            })
            .then(res => res.json())
            .then(res => {
                // Successfully logged with Strapi
                // Now saving the jwt to use it for future authenticated requests to Strapi
                localStorage.setItem('jwt', res.jwt);
                localStorage.setItem('username', res.user.username);
                localStorage.setItem('email', res.user.email);

                // Log Data
                console.log('Email:', res.user.email);
                console.log('Username:', res.user.username);
                console.log('jwt:', res.jwt);

                setText('You have been successfully logged in. You will be redirected in a few moments...');
                navigate("../dashboard", { replace: true });
                // setTimeout(() => navigate("../dashboard", { replace: true }), 1200); // Redirect to homepage after 3 sec
            })
            .catch(err => {
                console.log(err);
                setText('An error occurred, please see the developer console.')
            });
    }, [navigate, location.search, params.providerName]);

    return <Loader />
};

export default LoginRedirect;
