import Home from './Pages/Home/Home'
import Soon from './Pages/Soon/Soon'
import 'bootstrap/dist/css/bootstrap.min.css';
import PricePage from './Pages/PricingPage/PricingPage'
import NotFound from './Pages/Soon/NotFound'
import LoginRedirect from './Utils/LoginRedirect'

import IspPage from './Pages/Isp/Isp'
import HomeDashboard from './Pages/HomeDashboard/HomeDashboard'

import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

function App() {
  if (!process.env.REACT_APP_BACKEND_URL_TEST) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/purchase" element={<PricePage />} />
          <Route path="*" element={<NotFound />} />

          <Route path="/dashboard/" element={<HomeDashboard />} />
          {/* <Route path="/dashboard/home" element={< HomeDashboard />} /> */}
          <Route path="/dashboard/isp" element={<IspPage />} />
          <Route path="/dashboard/residential" element={<IspPage />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/purchase" element={<PricePage />} />
        <Route path="*" element={<NotFound />} />

        <Route path="/dashboard/" element={<Soon />} />

        {/* <Route path="/dashboard/" element={<HomeDashboard />} />
        <Route path="/dashboard/home" element={< HomeDashboard />} />
        <Route path="/dashboard/isp" element={<IspPage />} />
        <Route path="/dashboard/residential" element={<IspPage />} />

        <Route path="/connect/:discord/redirect" element={<LoginRedirect />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;



{/* <Route exact path="/connect/:discord/redirect" component={LoginRedirect} />
    <Route path="/connect/:discord/redirect" element={<LoginRedirect />} /> 
*/}